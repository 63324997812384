import React, { useState, useEffect } from "react";
import * as Ui from "components/ui";
import * as Layout from "components/layout";
import styled from "styled-components";
import tw from "tailwind.macro";
import "./pricingPage.scss";
import { calculatorConfig, plans } from "../../data/plans";

const SPeriodSwitcherButton = styled.div`
  ${tw`rounded-full px-4 py-1 bg-gray-200 text-gray-600`}
  cursor: pointer;
  position: relative;
  font-size: 14px;
  @media (max-width: 768px) {
    font-size: 12px;
    ${tw`px-3 py-1`}
  }
  &.active {
    ${tw`bg-white text-gray-800`}
  }
`;

const SPeriodSwitcher = styled.div`
  ${tw`rounded-full p-1 text-base font-medium text-gray-500`}
  background-color: #F9FAFB;
  display: inline-flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 325px;
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
`;

const SPlanToggler = styled.div`
  ${tw`flex rounded-full p-1`}
  width: 100%;
  background: white;
  border: 3px solid #6366f1;
  border-radius: 10px;
  padding: 4px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 4px;
  }
`;

const SPlanTogglerButton = styled.button`
  ${tw`flex-1 text-base font-bold`}
  transition: all 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  font-size: 20px;
  padding: 18px 36px;

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 12px 24px;
  }

  &.active {
    ${tw`text-white`}
    background: #6366F1;
    background: #6366f1;
  }
  &:not(.active) {
    ${tw`text-gray-900`}
  }
`;

const StickyFooter = styled.div`
  ${tw`max-w-7xl fixed bottom-0 left-0 right-0 text-white shadow-xl`}
  z-index: 40;
  @media (max-width: 768px) {
    ${tw`py-4`}
  }
`;

const FooterContent = styled.div`
  ${tw`rounded-xl p-6 px-10 rounded-b-none`}
  background: rgb(32, 34, 93,0.97);
`;

const PricingPage = () => {
  const [billingPeriod, setBillingPeriod] = useState("MONTH");
  const [selectedPlan, setSelectedPlan] = useState("basic");
  const [inputValues, setInputValues] = useState({});
  const [selectedButtonAddons, setSelectedButtonAddons] = useState(new Set());
  const [touchedInputs, setTouchedInputs] = useState(new Set());

  useEffect(() => {
    const newValues = {};
    calculatorConfig.forEach((section) => {
      section.addons.forEach((addon) => {
        if (addon.type === "input") {
          const pricing = addon.pricing[selectedPlan];
          if (pricing.available && !touchedInputs.has(addon.id)) {
            newValues[addon.id] = pricing.included;
          }
        }
      });
    });
    setInputValues((prev) => ({
      ...prev,
      ...newValues,
    }));
    // Clear selected button addons when plan changes
    setSelectedButtonAddons(new Set());
  }, [selectedPlan, touchedInputs]);

  const toggleButtonAddon = (addonId) => {
    setSelectedButtonAddons((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(addonId)) {
        newSet.delete(addonId);
      } else {
        newSet.add(addonId);
      }
      return newSet;
    });
  };

  const handleInputChange = (addonId, value) => {
    const addon = calculatorConfig
      .flatMap((section) => section.addons)
      .find((a) => a.id === addonId);

    const pricing = addon.pricing[selectedPlan];

    // Handle empty string
    if (value === "") {
      setInputValues((prev) => ({
        ...prev,
        [addonId]: "",
      }));
      setTouchedInputs((prev) => new Set([...prev, addonId]));
      return;
    }

    // Handle non-numeric input
    if (isNaN(value)) {
      return;
    }

    // Convert value to number and ensure it's not negative
    const numericValue = Math.max(0, Number(value));

    // Calculate additional units needed above what's included
    const additionalAmount = Math.max(0, numericValue - pricing.included);
    const additionalUnits = Math.ceil(additionalAmount / pricing.unit);

    // Final value is included amount plus any additional units
    const finalValue = numericValue;

    setInputValues((prev) => ({
      ...prev,
      [addonId]: finalValue,
    }));

    // Mark this input as touched
    setTouchedInputs((prev) => new Set([...prev, addonId]));
  };

  const calculateAddonPrice = (addon, value) => {
    const pricing = addon.pricing[selectedPlan];
    if (!pricing.available) return 0;

    // For basic plan, only calculate if explicitly enabled as an addon
    if (
      pricing.included &&
      selectedPlan === "basic" &&
      value <= pricing.included
    )
      return 0;

    // For plus plan, only calculate costs above what's included
    if (
      pricing.included &&
      selectedPlan === "plus" &&
      value <= pricing.included
    )
      return 0;

    // Check if value exceeds maximum
    if (value > pricing.max) {
      return "contact";
    }

    const additionalAmount = Math.max(0, value - pricing.included);
    const additionalUnits = Math.ceil(additionalAmount / pricing.unit);
    return additionalUnits * pricing.price_per_unit;
  };

  const renderAddonInput = (addon) => {
    const pricing = addon.pricing[selectedPlan];
    const isAvailable = pricing.available;

    if (addon.type === "input") {
      const currentValue = isAvailable
        ? inputValues[addon.id] === ""
          ? ""
          : inputValues[addon.id] || pricing.included
        : 0;
      const price = isAvailable
        ? calculateAddonPrice(addon, currentValue || 0)
        : 0;

      return (
        <div className={`py-4 ${!isAvailable ? "opacity-50" : ""}`}>
          <div className="font-bold">{addon.label}</div>
          <div className="text-base">{addon.description}</div>
          <div className="flex flex-col md:flex-row justify-between mt-2 gap-4">
            <div
              className="relative flex-1"
              style={{ width: "100%", maxWidth: "300px" }}
            >
              <div className="inline-flex items-center bg-white border border-gray-200 rounded-lg overflow-hidden w-full">
                <input
                  type="number"
                  value={currentValue}
                  onChange={(e) => handleInputChange(addon.id, e.target.value)}
                  className="px-4 py-2 border-none focus:ring-0 flex-1"
                  min={0}
                  disabled={!isAvailable}
                />
                <span className="px-3 py-2 bg-gray-50 text-gray-500 border-l capitalize whitespace-nowrap">
                  {addon.unit_label}s
                </span>
              </div>
              {isAvailable &&
                currentValue > pricing.included &&
                currentValue <= pricing.max && (
                  <div className="text-xs text-gray-500 mt-1">
                    {pricing.included} included with plan
                  </div>
                )}
              {!isAvailable && (
                <div className="text-xs text-gray-500 mt-1">
                  Not available in {selectedPlan} plan
                </div>
              )}
              {isAvailable && currentValue > pricing.max && (
                <div className="text-xs text-gray-500 mt-1">
                  Maximum value exceeded
                </div>
              )}
            </div>
            <div className="whitespace-nowrap">
              {price === "contact" ? (
                <span className="font-bold text-lg">Contact us</span>
              ) : (
                <>
                  <span className="font-bold text-lg">${price}</span>
                  <span className="opacity-75">/m</span>
                </>
              )}
            </div>
          </div>
        </div>
      );
    }

    if (addon.type === "button") {
      const isSelected = selectedButtonAddons.has(addon.id);
      const plusPlanPrice = addon.pricing.plus.price_per_unit;
      const currentPlanPrice = isAvailable
        ? pricing.price_per_unit
        : plusPlanPrice;

      return (
        <div className={`py-4 ${!isAvailable ? "opacity-50" : ""}`}>
          <div className="flex flex-col md:flex-row md:justify-between md:gap-20 gap-4">
            <div>
              <div className="font-bold">{addon.label}</div>
              <div className="text-base mt-1">{addon.description}</div>
            </div>
            <div className="md:ml-auto">
              <button
                className={`border-2 rounded-lg px-4 py-2 font-bold flex-nowrap whitespace-nowrap w-full md:w-auto ${
                  isSelected
                    ? "bg-indigo-500 text-white border-indigo-500"
                    : "border-dark text-dark"
                }`}
                disabled={!isAvailable}
                onClick={() => toggleButtonAddon(addon.id)}
              >
                <i
                  className={`fa fa-${
                    isSelected ? "minus" : "plus"
                  }-circle mr-2`}
                ></i>
                {isSelected
                  ? `Remove $${currentPlanPrice}/m`
                  : `Add for $${currentPlanPrice}/m`}
              </button>
            </div>
          </div>
          {!isAvailable && (
            <div className="text-xs text-gray-500 mt-1">
              Not available in {selectedPlan} plan. Available in Plus plan for $
              {plusPlanPrice}/m
            </div>
          )}
        </div>
      );
    }
  };

  const calculateTotal = () => {
    // Base price is only for plus plan
    const basePrice = selectedPlan === "plus" ? 99 : 0;

    // Check if any input exceeds its maximum value
    const hasExceededMax = calculatorConfig
      .flatMap((section) => section.addons)
      .some((addon) => {
        if (addon.type === "input") {
          const currentValue =
            inputValues[addon.id] || addon.pricing[selectedPlan].included;
          return currentValue > addon.pricing[selectedPlan].max;
        }
        return false;
      });

    if (hasExceededMax) {
      return "contact";
    }

    // Calculate additional costs from input addons
    const inputAddonsTotal = calculatorConfig
      .flatMap((section) => section.addons)
      .reduce((total, addon) => {
        if (addon.type === "input") {
          const currentValue =
            inputValues[addon.id] || addon.pricing[selectedPlan].included;
          const pricing = addon.pricing[selectedPlan];

          if (selectedPlan === "basic" && currentValue > pricing.included) {
            const additionalUnits = Math.ceil(
              (currentValue - pricing.included) / pricing.unit
            );
            return total + additionalUnits * pricing.price_per_unit;
          }

          if (selectedPlan === "plus" && currentValue > pricing.included) {
            const additionalUnits = Math.ceil(
              (currentValue - pricing.included) / pricing.unit
            );
            return total + additionalUnits * pricing.price_per_unit;
          }
        }
        return total;
      }, 0);

    // Calculate costs from selected button addons
    const buttonAddonsTotal = calculatorConfig
      .flatMap((section) => section.addons)
      .reduce((total, addon) => {
        if (
          addon.type === "button" &&
          selectedButtonAddons.has(addon.id) &&
          addon.pricing[selectedPlan].available
        ) {
          return total + addon.pricing[selectedPlan].price_per_unit;
        }
        return total;
      }, 0);

    const monthlyTotal = basePrice + inputAddonsTotal + buttonAddonsTotal;
    return billingPeriod === "YEAR" ? monthlyTotal * 0.8 : monthlyTotal;
  };

  return (
    <Layout.Layout>
      <Layout.SEO refKey="pricing" />
      <Layout.HeroEmpty></Layout.HeroEmpty>

      <Ui.Section>
        <Ui.Container>
          <div className="flex flex-col md:flex-row md:mt-24 md:gap-12 gap-6">
            <div className="w-full md:w-5/12 flex flex-col gap-6">
              <div>
                <h1 className="font-heading text-3xl md:text-4xl leading-tight font-black text-dark">
                  Pricing Calculator
                </h1>
                <SPeriodSwitcher className="mt-3">
                  <SPeriodSwitcherButton
                    className={billingPeriod === "MONTH" ? "active" : ""}
                    onClick={() => setBillingPeriod("MONTH")}
                  >
                    Pay monthly
                  </SPeriodSwitcherButton>
                  <SPeriodSwitcherButton
                    className={billingPeriod === "YEAR" ? "active" : ""}
                    onClick={() => setBillingPeriod("YEAR")}
                  >
                    Pay yearly{" "}
                    <span className="text-sm text-gray-400">save 20%</span>
                  </SPeriodSwitcherButton>
                </SPeriodSwitcher>
              </div>
            </div>
            <div className="w-full md:w-7/12">
              <SPlanToggler>
                <SPlanTogglerButton
                  className={selectedPlan === "basic" ? "active" : ""}
                  onClick={() => setSelectedPlan("basic")}
                >
                  <span>{plans[0]["name"]}</span>
                  <span>
                    $
                    {billingPeriod === "MONTH"
                      ? plans[0]["priceMonthly"]
                      : plans[0]["priceYearly"]}
                    /m
                  </span>
                </SPlanTogglerButton>
                <SPlanTogglerButton
                  className={selectedPlan === "plus" ? "active" : ""}
                  onClick={() => setSelectedPlan("plus")}
                >
                  <span>{plans[1]["name"]}</span>
                  <span>
                    $
                    {billingPeriod === "MONTH"
                      ? plans[1]["priceMonthly"]
                      : plans[1]["priceYearly"]}
                    /m
                  </span>
                </SPlanTogglerButton>
              </SPlanToggler>
            </div>
          </div>
        </Ui.Container>
      </Ui.Section>

      <Ui.Section className="pb-32">
        <Ui.Container>
          <div className="m-auto mt-12 text-dark">
            <div className="grid grid-cols-1 gap-6 text-base">
              {calculatorConfig.map((section) => (
                <div
                  key={section.id}
                  className="rounded-xl bg-gray-lighter p-4 md:p-8 flex flex-col md:flex-row md:gap-20 gap-6"
                >
                  <div className="w-full md:w-5/12">
                    <h2 className="text-2xl font-extrabold">{section.label}</h2>
                    <p className="text-base text-gray-500 mt-2">
                      {section.description}
                    </p>
                  </div>
                  <div className="w-full md:w-7/12 -mt-4 divide-y-2 divide-gray-200">
                    {section.addons.map((addon) => renderAddonInput(addon))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Ui.Container>
      </Ui.Section>

      <StickyFooter>
        <Ui.Container>
          <FooterContent className="flex flex-col divide-y-2 divide-white divide-opacity-20 gap-3 md:gap-4 max-w-4xl m-auto ">
            <div className="flex flex-col md:flex-row items-center justify-between gap-4">
              <div className="flex items-center gap-3">
                <div className="text-2xl md:text-3xl font-bold">Total</div>
              </div>
              <div className="flex items-center gap-4 md:gap-6">
                {calculateTotal() === "contact" ? (
                  <div className="text-2xl md:text-3xl font-bold">
                    Contact us
                  </div>
                ) : billingPeriod === "YEAR" ? (
                  <div className="flex items-center gap-3">
                    {calculateTotal() != 0 ? (
                      <div className="text-xl md:text-2xl line-through opacity-50">
                        ${Math.round(calculateTotal() / 0.8)}
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="text-2xl md:text-3xl font-bold">
                      ${Math.round(calculateTotal())}
                      <span className="text-base md:text-lg font-normal ml-2 opacity-75">
                        /mo
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="text-2xl md:text-3xl font-bold">
                    ${Math.round(calculateTotal())}
                    <span className="text-base md:text-lg font-normal ml-2 opacity-75">
                      /mo
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="text-sm md:text-base pt-4 md:pt-6">
              <div className="flex flex-col md:flex-row justify-between gap-4">
                <div>
                  <div className="mb-2">
                    Includes{" "}
                    <span className="font-bold">
                      {selectedPlan.charAt(0).toUpperCase() +
                        selectedPlan.slice(1)}{" "}
                      Plan
                    </span>{" "}
                    and optional add-ons
                  </div>
                  <Ui.Link
                    arrow="right"
                    to="/pricing/"
                    underline={false}
                    className="text-sm md:text-base"
                  >
                    See plans comparison
                  </Ui.Link>
                </div>
                <div className="flex flex-col md:flex-row gap-3 w-full md:w-auto">
                  <button className="px-4 md:px-6 py-2 border-2 border-indigo-400 text-primary rounded-lg font-bold w-full md:w-auto">
                    Contact Sales
                  </button>
                  <button className="px-4 md:px-6 py-2 bg-indigo-500 text-white rounded-lg font-medium w-full md:w-auto">
                    Start a free trial
                  </button>
                </div>
              </div>
            </div>
          </FooterContent>
        </Ui.Container>
      </StickyFooter>
    </Layout.Layout>
  );
};

export default PricingPage;
